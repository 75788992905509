import React from 'react';
import {RadioButtonGroup, RadioButtonTheme} from 'wix-ui-tpa/cssVars';
import {classes} from './DeliveryMethodPicker.st.css';
import {DeliveryMethodPickerDataHook} from './DeliveryMethodPicker';
import {DELIVERY_METHOD_GROUP_NAME} from '../../constants';

export interface DeliveryMethodGroupProps {
  title: string;
  onSelected: (selectedId: string) => void;
  children: any;
  showHeader: boolean;
  selectedId?: string;
}

export const DeliveryMethodGroup = ({
  title,
  onSelected,
  children,
  showHeader,
  selectedId,
}: DeliveryMethodGroupProps) => {
  return (
    <div className={classes.sectionContainer}>
      <RadioButtonGroup
        label={showHeader ? <span data-hook={DeliveryMethodPickerDataHook.sectionTitle}>{title}</span> : undefined}
        data-hook={DeliveryMethodPickerDataHook.shippingRadioGroup}
        className={classes.radioGroup}
        name={DELIVERY_METHOD_GROUP_NAME}
        theme={RadioButtonTheme.Box}
        value={selectedId}
        fluid={true}
        onChange={(selectedId) => onSelected(selectedId)}>
        {children}
      </RadioButtonGroup>
    </div>
  );
};
